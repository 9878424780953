import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);
import { index } from "./components/index";

index();

$(function () {
  $(document).on('click', '.nav__hamburger', function () {
    $('.header').toggleClass('is_active');
  })
  $('a[href^="#"]').click(function () {
    let speed = 400;
    let type = 'swing';
    let href = $(this).attr("href");
    let target = $(href == "#index" ? 'html' : href);
    let position = target.offset().top;
    $('body,html').animate({ scrollTop: position }, speed, type);
    return false;
  });
})


$(window).on('load', function () {


  if ($('.interviewpage__header').length) {
    setTimeout(() => {
      // $('.loader').addClass('is_load');
      $('.interviewpage__mainimage').addClass('is_active');
    }, 500);

    ScrollTrigger.create({
      trigger: ".interviewpage__header",
      start: "top-=110px center",
      endTrigger: ".footer",
      end: "bottom+=200px bottom",
      // markers: true,
      //invalidateOnRefresh: true,
      toggleClass: 'is_active',
      once: true,
    });

    ScrollTrigger.batch('.interviewpage__header h2 span', {
      start: "top center",
      endTrigger: ".footer",
      end: "bottom+=200px bottom",
      //invalidateOnRefresh: true,
      // toggleClass: 'is_active',
      once: true,
      // markers: true,
      onEnter: function (batch) {
        console.log(batch);
        for (let i = 0; i < batch.length; i++) {
          setTimeout(() => {
            $(batch[i]).addClass('is_active');
          }, i * 500);
          setTimeout(() => {
            $(batch[i]).addClass('is_text');
          }, i * 500);
        }
      }
    });


    ScrollTrigger.create({
      trigger: ".interviewpage__body",
      start: "top top",
      endTrigger: ".footer",
      end: "bottom+=200px bottom",
      // markers: true,
      //invalidateOnRefresh: true,
      // toggleClass: { targets: ".header", className: "is_fixed" },
      once: false,
      onEnter: function () {
        $(".header").addClass('is_fixed')
        setTimeout(() => {
          $(".header").animate({
            opacity: 1
          }, 250)
        }, 750);
      },
      onLeaveBack: function () {
        $(".header").animate({
          opacity: 0
        }, 500)
        setTimeout(() => {
          $(".header").removeClass('is_fixed').removeAttr('style')
        }, 1000);
      }
    });
  }


  // fade in
  gsap.set(".fadeup", { opacity: 0, y: 50 });
  ScrollTrigger.batch('.fadeup', {
    onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, }),
    onLeaveBack: batch => gsap.to(batch, { opacity: 0, y: 50 }),
    start: "top center",
    // markers: true
  });
})
