// const moment = require('moment');
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
gsap.registerPlugin(ScrollTrigger);

export function index() {
  $('.toppage .loader').addClass('is_active');
  $('.toppage .illustrator').addClass('is_active');
  ScrollTrigger.create({
    trigger: ".footer",
    start: "top bottom",
    end: "bottom+=200px bottom",
    // markers: true,
    //invalidateOnRefresh: true,
    toggleClass: { targets: ".toppage .illustrator", className: "is_none" },
    once: false,
  });
  /*
    ScrollTrigger.create({
      trigger: ".toppage__nav .item",
      start: "top center",
      endTrigger: ".footer",
      end: "bottom+=200px bottom",
      markers: true,
      //invalidateOnRefresh: true,
      toggleClass: 'is_active',
      once: false,
    });
    */

  ScrollTrigger.batch('.toppage__nav .item', {
    start: "top center",
    endTrigger: ".footer",
    end: "bottom+=200px bottom",
    //invalidateOnRefresh: true,
    // toggleClass: 'is_active',
    once: false,
    // markers: true
    onEnter: function (batch) {
      console.log(batch);
      for (let i = 0; i < batch.length; i++) {
        setTimeout(() => {
          $(batch[i]).addClass('is_active');
        }, i * 500);
      }
    },
    onLeaveBack: function (batch) {
      $(batch).removeClass('is_active');
    }
  });


  const shuffle = ([...array]) => {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  $(window).on('load', function () {
    setTimeout(() => {
      $('.loader').addClass('is_load');
      $('.toppage .illustrator').addClass('is_load');
      $('.interviewpage__mainimage').addClass('is_active');


      const imgarray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
      const shufflearray = shuffle(imgarray);

      for (let index = 0; index <= shufflearray.length; index++) {
        console.log(shufflearray[index])
        $('.toppage__mainimage .photo' + shufflearray[index]).delay(200 * index).fadeIn(700);
        if (index === shufflearray.length) {
          $('.toppage__mainimage h1').delay((110 * index) + 1000).fadeIn(2000);
          setTimeout(() => {
            $('.toppage__mainimage .illustrator').addClass('is_active');
          }, (100 * index + 1000));
        }
      }
    }, 2500);
  });
}
